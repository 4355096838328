@import url(//fonts.googleapis.com/css?family=Montserrat:400:300:500:600);

.bg-login {
    height: 100%;
    min-height: 100vh;
}

.bg-login img {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.container-login {
    /* padding: 250px 0; */
    background-color: #0d58e2;
}

.card-login {
    background-color: #fefefe;
    min-height: 300px;
    width: 100%;
    padding:50px;
    margin-top: 250px;
    margin-left: auto;
    margin-right: auto;
}

::placeholder {
    color: #7C7C7C !important;
    
}

:-ms-input-placeholder {
    color: #7C7C7C !important;
    
}

::-ms-input-placeholder {
    color: #7C7C7C !important;
    
}

.input-icons span {
    position: absolute;
    color: #7C7C7C;
}

.input-icons {
    width: 100%;
    margin-bottom: 10px;
}

.icon {
    padding: 15px 5px 5px 5px;
    min-width: 40px;
    vertical-align: baseline !important;
}

.login-form {
    background: #FFFFFF;
    width: 100%;
    padding: 10px;
    border: solid #FFFFFF !important;
    box-shadow: 0px 4px 15px rgba(212, 209, 209, 0.3);
    border-radius: 8px;
    text-indent: 20px;
}

.btn-login {
    background-color: #0d58e2;
    color: #fefefe;
    border-radius: 30px;
}